import { useContext, type PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';
import {
  type DialogProps as RACDialogProps,
  type ModalOverlayProps,
  Dialog as RACDialog,
  Modal,
  OverlayTriggerStateContext,
  composeRenderProps,
  ModalOverlay,
  Heading,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { Button, type ButtonProps } from './button';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { LabCloseIcon } from 'components/camperlab/icons/close';

import { overlayStyles } from './dialog';
import { getDomainValue } from 'utils/domain';

const drawerStyles = tv({
  base: 'lg:min-w- fixed bottom-0 right-0 top-0 h-full max-h-full bg-clear bg-clip-padding text-left align-middle max-sm:w-full lg:w-[calc(25vw+10px)] lg:min-w-[340px] forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'duration-200 ease-out animate-in slide-in-from-right',
    },
    isExiting: {
      true: 'duration-200 ease-in animate-out slide-out-to-right',
    },
  },
});

export type DrawerRootProps = ModalOverlayProps & {
  showOverlay?: boolean;
};

export function DrawerRoot({ className, children, isDismissable = true, showOverlay = false, ...props }: DrawerRootProps) {
  return (
    <ModalOverlay
      data-overlay
      {...props}
      isDismissable={isDismissable}
      className={composeRenderProps('', (className, renderProps) => overlayStyles({ ...renderProps, className, showOverlay }))}
    >
      <Modal className={composeRenderProps(className ?? '', (className, renderProps) => drawerStyles({ ...renderProps, className }))}>{children}</Modal>
    </ModalOverlay>
  );
}

export function Drawer(props: RACDialogProps) {
  return <RACDialog {...props} className={twMerge('relative h-dvh max-h-[inherit] overflow-auto outline outline-0', props.className)} />;
}

export function DrawerX({ onClose, absolute = true, className }: { onClose?: () => void; absolute?: boolean; className?: string }) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      variant="none"
      onPress={() => {
        onClose?.();
        state.close();
      }}
      className={className ?? `${absolute ? 'absolute right-0 top-0 m-0.5' : ''} flex size-7 items-center justify-center lab:-ml-2.5 lab:-mt-2.5`}
    >
      <span className="sr-only">{'Close'}</span>
      {getDomainValue({ camper: <CloseModalSVG aria-hidden />, nnormal: <CloseModalSVG aria-hidden />, camperlab: <LabCloseIcon aria-hidden /> })}
    </Button>
  );
}

type DrawerCloseProps = ButtonProps & {
  onClose?: () => void;
};

export function useDrawerContext() {
  return useContext(OverlayTriggerStateContext);
}

export function DrawerClose({ onClose, ...rest }: DrawerCloseProps) {
  const state = useContext(OverlayTriggerStateContext);
  return (
    <Button
      {...rest}
      onPress={() => {
        onClose?.();
        state.close();
      }}
    />
  );
}

export function DrawerBody({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={`flex-grow px-2.5 ${className}`}>{children}</div>;
}

export function DrawerFooter({ children }: PropsWithChildren<{}>) {
  return <div className="absolute bottom-0 flex w-full flex-col gap-[5px] p-2.5">{children}</div>;
}

export function DrawerHeader({ label, position = 'sticky', className = '' }: { label: string; position?: 'sticky' | 'absolute'; className?: string }) {
  return (
    <div className={`${position} top-0 z-10 mb-[130px] flex flex-shrink-0 items-center justify-between bg-clear py-2 ${className}`}>
      <Heading slot="title" className="sr-only mb-0 text-sm font-bold uppercase lg:not-sr-only lg:text-xs">
        {label}
      </Heading>
      <div>
        <DrawerClose variant="none" className="flex size-7 items-center justify-center lab:size-4 lg:lab:size-3.5">
          <span className="sr-only">{'Close'}</span>
          {getDomainValue({ camper: <CloseModalSVG aria-hidden />, nnormal: <CloseModalSVG aria-hidden />, camperlab: <LabCloseIcon aria-hidden /> })}
        </DrawerClose>
      </div>
    </div>
  );
}
